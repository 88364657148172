import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import DepositEntriesApi from './DepositEntriesApi';
import { wpOldLink } from '../../functions/wpOldLink';
import OriginalModelLink from '../../elements/OriginalModelLink/OriginalModelLink';
import { Status, WhiteSpace } from '@dex/bubl-dash';


let settings;

export default settings = {
    key: 'deposit-entries',
    zone: 'finance',
    path: '/deposit-entries',
    title: "Deposit Entries",
    endpoint: DepositEntriesApi.endpoint,
    modelName: 'DepositEntries',
    idKey: 'id',
    primaryKey: 'id',
    indexCaps: ["viewDepositEntries"],
    viewCaps: ["viewDepositEntries"],
    createCaps: ["createDepositEntries"],
    actions: ['trash'],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            // {
            //     label: "Add New",
            //     path: settings.path + "/create"
            // },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            // {
            //     label: "Add New",
            //     path: settings.path + '/create',
            //     caps: settings.createCaps
            // }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: DepositEntriesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = {
            where: {},
            include: [
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'invoice',
                    preset: "link"
                },
            ],
            order: ['created.date DESC'], limit: 10, skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },

    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "depositEntries", label: "DepositEntries" }
        ];

        if (!user || !user.caps.includes("exportDepositEntries")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', "merchant_id", "invoice_id", "company_name"];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
            {
                label: "Merchant ID",
                key: "merchant_id",
                type: "text"
            },
            {
                label: "Company Name",
                key: "company_name",
                type: "text"
            },
            {
                label: "Invoice ID",
                key: "invoice_id",
                type: "relationship",
                model: "Invoices"
            },
            {
                label: "Status",
                key: "status",
                type: "choice",
                options: [
                    { value: "new", label: "New" },
                    { value: "refunded", label: "Refunded" },
                ]
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
                sort: "id",
            },
            {
                label: "Invoice ID",
                key: "invoice",
                format: "ModelLink",
            },
            {
                label: "Merchant ID",
                key: "merchant",
                format: "ModelLink",
            },
            {
                label: "Company Name",
                key: "company_name",
                elipsis: true,
            },
            {
                label: "Deposit",
                key: "deposit",
                format: "number",
                prefix: "RM ",
                alignMiddle: "true"
            },
            {
                label: "Status",
                key: "status",
                format: "status",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: DepositEntriesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: DepositEntriesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: [
                {
                    relation: 'merchant',
                    preset: "link"
                },
                {
                    relation: 'invoice',
                    preset: "link"
                },
            ]
        };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: any = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = <>
            {data[settings.primaryKey]}
            <>
                <WhiteSpace />
                <Status status={data.status} />
            </>

        </>;
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            // {
            //     label: "Edit",
            //     path: path + "/edit"
            // },
        ]

    },
    singleButtons: (routeName, params, user, fetch) => {

        return [
            wpOldLink(fetch.data),
        ].filter(Boolean);

    },

};